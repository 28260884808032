import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

//import wagner from "assets/img/wagner/wagner_circle.png";
import soccer from "assets/img/wagner/wagner_soccer.png";
//import image6 from "assets/img/stingsoccer/pic-6.jpg";
//import image7 from "assets/img/stingsoccer/pic-7.jpg";


import styles from "assets/jss/material-kit-react/views/componentsSections/typographyStyle.js";

const useStyles = makeStyles(styles);

export default function SectionTypography() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>

        <div className={classes.space50} />
        <div id="images">
          <div className={classes.title}>
            <h2>Albums</h2>
          </div>
          <br />
          <GridContainer>
            <GridItem xs={12} sm={2}>
              <h7>2020-2021 Boy's Soccer</h7>
                <h6>All Albums</h6>
              <a href="https://www.amazon.com/clouddrive/share/c2c7gZka44D22I6Kp76Mms12a9lfWb2hLHQjMyCkO13"
                target="_blank" rel="noopener noreferrer">
              <img
                src={soccer}
                alt="..."
                className={classes.imgRounded + " " + classes.imgFluid}
              />
              </a>
            </GridItem>
            <GridItem xs={12} sm={2}>
              <h7>2020-2021 Girl's Soccer</h7>
                <h6>All Albums</h6>
              <a href="https://www.amazon.com/clouddrive/share/qUT9xHMglCdlvUwroJtgwMTk9gTjVrsHlsYV6i83tvn"
                target="_blank" rel="noopener noreferrer">
              <img
                src={soccer}
                alt="..."
                className={classes.imgRounded + " " + classes.imgFluid}
              />
              </a>
            </GridItem>
          </GridContainer>
          <GridContainer />
        </div>
        <div className={classes.space50} />
      </div>
    </div>
  );
}

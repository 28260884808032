import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

//import sting from "assets/img/stingsoccer/sting-bg.jpg";
//import committed from "assets/img/tcsidekicksteam/committed.png";
import email from "assets/img/tcsidekicksteam/email.png";
//import twitter from "assets/img/tcsidekicksteam/twitter.png"
import coach from "assets/img/gsaforcefc07bw/coach.jpg";
import roster from "assets/img/gsaforcefc07bw/roster.pdf";
//import hudl from "assets/img/tcsidekicksteam/hudl.png";
//import flickr from "assets/img/tcsidekicksteam/flickr.png";
import image2 from "assets/img/gsaforcefc07bw/2.jpg";
import image4 from "assets/img/gsaforcefc07bw/4.jpg";
import image5 from "assets/img/gsaforcefc07bw/5.jpg";
import image6 from "assets/img/gsaforcefc07bw/6.jpg";
import image8 from "assets/img/gsaforcefc07bw/8.jpg";
import image9 from "assets/img/gsaforcefc07bw/9.jpg";
import image11 from "assets/img/gsaforcefc07bw/11.jpg";
import image13 from "assets/img/gsaforcefc07bw/13.jpg";
import image14 from "assets/img/gsaforcefc07bw/14.jpg";
import image15 from "assets/img/gsaforcefc07bw/15.jpg";
import image16 from "assets/img/gsaforcefc07bw/16.jpg";
import image19 from "assets/img/gsaforcefc07bw/19.jpg";
import image20 from "assets/img/gsaforcefc07bw/20.jpg";
import image21 from "assets/img/gsaforcefc07bw/21.jpg";
import image23 from "assets/img/gsaforcefc07bw/23.jpg";
import image25 from "assets/img/gsaforcefc07bw/25.jpg";
import image30 from "assets/img/gsaforcefc07bw/30.jpg";
import image55 from "assets/img/gsaforcefc07bw/55.jpg";
import image99 from "assets/img/gsaforcefc07bw/99.jpg";


import styles from "assets/jss/material-kit-react/views/componentsSections/typographyStyle.js";

const useStyles = makeStyles(styles);

export default function SectionTypography() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <img src={require("assets/img/gsaforcefc07bw/Crest-and-Logo.webp")} width="350" alt="Georgetown Soccer Association">
                </img>
        <div className={classes.space50} />
        <div id="images">

          <div>
            
            <h1>GSA Force FC 07 Boys Blue</h1>
          </div>
      

      
          <div>
            <div>
                <img
                    src={coach}
                    alt="..."
                    className={classes.imgRounded + " " + classes.imgFluid}
                    width="300px"
                  />
                  <h3>Coach Billy Willard</h3>
                  <h4>(214) 908-2792</h4>
                  <h4>Contact Me &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="mailto:billy.willard@georgetownsoccer.org"><img src={email} alt="..." style={{width:'40px'}} /></a></h4>
            </div>
          </div>
                      <br />
                  
                      
          <div>
              <a href={roster}><Button type="button" color="primary">Download Team Roster</Button></a>
          </div>
        <br />
        <br />
          <h6>Click the envelope if you would like to contact the player by email.</h6>
          <br />
          <GridContainer>
            <GridItem xs={10} sm={3}>
              <img
                src={image2}
                alt="..."
                className={classes.imgRounded + " " + classes.imgFluid}
              />
                <h6>Georgetown High School</h6>
                <h6>Forward</h6>
                <h6>Contact Me &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="mailto:robbyjr88@gmail.com"><img src={email} alt="..." style={{width:'40px'}} /></a></h6>
            </GridItem>

            <GridItem xs={10} sm={3}>
              <img
                src={image4}
                alt="..."
                className={classes.imgRounded + " " + classes.imgFluid}
              />
                <h6>East View High School</h6>
                <h6>Midfield</h6>
                <h6>Contact Me &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="mailto:brazuca2026@gmail.com"><img src={email} alt="..." style={{width:'40px'}} /></a></h6>
            </GridItem>

            <GridItem xs={10} sm={3}>
              <img
                src={image5}
                alt="..."
                className={classes.imgRounded + " " + classes.imgFluid}
              />
                <h6>East View High School</h6>
                <h6>Forward</h6>
                <h6>Contact Me &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="mailto:shaunabauer@yahoo.com"><img src={email} alt="..." style={{width:'40px'}} /></a></h6>
            </GridItem>

            <GridItem xs={10} sm={3}>
              <img
                src={image6}
                alt="..."
                className={classes.imgRounded + " " + classes.imgFluid}
              />
                <h6>Liberty Hill High School</h6>
                <h6>Midfield</h6>
                <h6>Contact Me &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="mailto:scsvatek@yahoo.com"><img src={email} alt="..." style={{width:'40px'}} /></a></h6>
            </GridItem>

            <GridItem xs={10} sm={3}>
              <img
                src={image8}
                alt="..."
                className={classes.imgRounded + " " + classes.imgFluid}
              />
                <h6>East View High School</h6>
                <h6>Defense</h6>
                <h6>Contact Me &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="mailto:ajbarb2007@gmail.com"><img src={email} alt="..." style={{width:'40px'}} /></a></h6>
            </GridItem>

            <GridItem xs={10} sm={3}>
              <img
                src={image9}
                alt="..."
                className={classes.imgRounded + " " + classes.imgFluid}
              />
                <h6>Georgetown High School</h6>
                <h6>Midfield</h6>
                <h6>Contact Me &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="mailto:chewykeefe@yahoo.com"><img src={email} alt="..." style={{width:'40px'}} /></a></h6>
            </GridItem>

            <GridItem xs={10} sm={3}>
              <img
                src={image11}
                alt="..."
                className={classes.imgRounded + " " + classes.imgFluid}
              />
                <h6>Stony Point High School</h6>
                <h6>Forward</h6>
                <h6>Contact Me &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="mailto:dondiogio@icloud.com"><img src={email} alt="..." style={{width:'40px'}} /></a></h6>
            </GridItem>

            <GridItem xs={10} sm={3}>
              <img
                src={image13}
                alt="..."
                className={classes.imgRounded + " " + classes.imgFluid}
              />
                <h6>Georgetown High School</h6>
                <h6>Goalkeeper</h6>
                <h6>Contact Me &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="mailto:mtorres_1131@hotmail.com"><img src={email} alt="..." style={{width:'40px'}} /></a></h6>
            </GridItem>

            <GridItem xs={10} sm={3}>
              <img
                src={image14}
                alt="..."
                className={classes.imgRounded + " " + classes.imgFluid}
              />
                <h6>East View High School</h6>
                <h6>Defense</h6>
                <h6>Contact Me &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="mailto:we_ez@hotmail.com"><img src={email} alt="..." style={{width:'40px'}} /></a></h6>
            </GridItem>

            <GridItem xs={10} sm={3}>
              <img
                src={image15}
                alt="..."
                className={classes.imgRounded + " " + classes.imgFluid}
              />
                <h6>Georgetown High School</h6>
                <h6>Defense</h6>
                <h6>Contact Me &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="mailto:ryanmillegan.08@gmail.com"><img src={email} alt="..." style={{width:'40px'}} /></a></h6>
            </GridItem>

            <GridItem xs={10} sm={3}>
              <img
                src={image16}
                alt="..."
                className={classes.imgRounded + " " + classes.imgFluid}
              />
                <h6>Georgetown High School</h6>
                <h6>Defense</h6>
                <h6>Contact Me &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="mailto:kauliwhitmire@yahoo.com"><img src={email} alt="..." style={{width:'40px'}} /></a></h6> 
            </GridItem>

            <GridItem xs={10} sm={3}>
              <img
                src={image19}
                alt="..."
                className={classes.imgRounded + " " + classes.imgFluid}
              />
                <h6>Glenn High School</h6>
                <h6>Midfield</h6>
                <h6>Contact Me &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="mailto:saranejhrig@gmail.com"><img src={email} alt="..." style={{width:'40px'}} /></a></h6>
            </GridItem>

            <GridItem xs={10} sm={3}>
              <img
                src={image20}
                alt="..."
                className={classes.imgRounded + " " + classes.imgFluid}
              />
                <h6>Georgetown High School</h6>
                <h6>Defense</h6>
                <h6>Contact Me &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="mailto:owenwillard@icloud.com"><img src={email} alt="..." style={{width:'40px'}} /></a></h6>
            </GridItem>

            <GridItem xs={10} sm={3}>
              <img
                src={image21}
                alt="..."
                className={classes.imgRounded + " " + classes.imgFluid}
              />
                <h6>Georgetown High School</h6>
                <h6>Forward</h6>
                <h6>Contact Me &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="mailto:efranco86@hotmail.com"><img src={email} alt="..." style={{width:'40px'}} /></a></h6>
            </GridItem>


            <GridItem xs={10} sm={3}>
              <img
                src={image23}
                alt="..."
                className={classes.imgRounded + " " + classes.imgFluid}
              />
                <h6>Georgetown High School</h6>
                <h6>Forward</h6>
                <h6>Contact Me &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="mailto:hollyurbanek@dell.com"><img src={email} alt="..." style={{width:'40px'}} /></a></h6>
            </GridItem>

            <GridItem xs={10} sm={3}>
              <img
                src={image25}
                alt="..."
                className={classes.imgRounded + " " + classes.imgFluid}
              />
                <h6>Georgetown High School</h6>
                <h6>Forward</h6>
                <h6>Contact Me &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="mailto:ipadrontx@gmail.com"><img src={email} alt="..." style={{width:'40px'}} /></a></h6>
            </GridItem>

            <GridItem xs={10} sm={3}>
              <img
                src={image30}
                alt="..."
                className={classes.imgRounded + " " + classes.imgFluid}
              />
                <h6>East View High School</h6>
                <h6>Forward</h6>
                <h6>Contact Me &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="mailto:badgerssellers016@gmail.com"><img src={email} alt="..." style={{width:'40px'}} /></a></h6>
            </GridItem>

            <GridItem xs={10} sm={3}>
              <img
                src={image55}
                alt="..."
                className={classes.imgRounded + " " + classes.imgFluid}
              />
                <h6>Georgetown High School</h6>
                <h6>Forward</h6>
                <h6>Contact Me &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="mailto:mtorres_1131@hotmail.com"><img src={email} alt="..." style={{width:'40px'}} /></a></h6>
            </GridItem>

            <GridItem xs={10} sm={3}>
              <img
                src={image99}
                alt="..."
                className={classes.imgRounded + " " + classes.imgFluid}
              />
                <h6>East View High School</h6>
                <h6>Midfield</h6>
                <h6>Contact Me &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="mailto:willardfamily06@hotmail.com"><img src={email} alt="..." style={{width:'40px'}} /></a></h6>
            </GridItem>
            
          </GridContainer>
        </div>
        <div className={classes.space50} />
      </div>
    </div>
  );
}

import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import soccer from "assets/img/eastview/ev-soccer-logo.png";
import softball from "assets/img/eastview/softball.jpg";
import track from "assets/img/eastview/track.jpg";
import evpp from "assets/img/eastview/evpp.jpg";
import volleyball from "assets/img/eastview/volleyball.jpeg";
import xc from "assets/img/eastview/ev_cc.png";
import football from "assets/img/eastview/football.jpg";
import sparklers from "assets/img/eastview/sparklers.jpg";
import cheer from "assets/img/eastview/cheer.jpg";
import band from "assets/img/eastview/band.jpg";


import styles from "assets/jss/material-kit-react/views/componentsSections/typographyStyle.js";

const useStyles = makeStyles(styles);

export default function SectionTypography() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>

        <div className={classes.space50} />
        <div id="images">
          <div className={classes.title}>
            <h2>Albums</h2>
          </div>
          <br />
          <GridContainer>
            <GridItem xs={12} sm={2}>
              <h7>2021-2022 Patriot Football</h7>
                <h6>All Albums</h6>
              <a href="https://www.flickr.com/photos/creativedna/collections/72157719775777067/"
                target="_blank" rel="noopener noreferrer">
              <img
                src={football}
                alt="..."
                className={classes.imgRounded + " " + classes.imgFluid}
              />
              </a>
            </GridItem>
            <GridItem xs={12} sm={2}>
              <h7>2021-2022 Patriot Cross Country</h7>
                <h6>All Albums</h6>
              <a href="https://www.flickr.com/photos/creativedna/collections/72157719764556628/"
                target="_blank" rel="noopener noreferrer">
              <img
                src={xc}
                alt="..."
                className={classes.imgRounded + " " + classes.imgFluid}
              />
              </a>
            </GridItem>
            <GridItem xs={12} sm={2}>
              <h7>2021-2022 Women's Volleyball</h7>
                <h6>All Albums</h6>
              <a href="https://www.flickr.com/photos/creativedna/collections/72157719766559154/"
                target="_blank" rel="noopener noreferrer">
              <img
                src={volleyball}
                alt="..."
                className={classes.imgRounded + " " + classes.imgFluid}
              />
              </a>
            </GridItem>
            <GridItem xs={12} sm={2}>
              <h7>2021-2022 Patriot Sparklers</h7>
                <h6>All Albums</h6>
              <a href="https://www.flickr.com/photos/creativedna/albums/72157719818778590"
                target="_blank" rel="noopener noreferrer">
              <img
                src={sparklers}
                alt="..."
                className={classes.imgRounded + " " + classes.imgFluid}
              />
              </a>
            </GridItem>
            <GridItem xs={12} sm={2}>
              <h7>2021-2022 Patriot Cheer</h7>
                <h6>All Albums</h6>
              <a href="https://www.flickr.com/photos/creativedna/albums/72157719776425072"
                target="_blank" rel="noopener noreferrer">
              <img
                src={cheer}
                alt="..."
                className={classes.imgRounded + " " + classes.imgFluid}
              />
              </a>
            </GridItem>
            <GridItem xs={12} sm={2}>
              <h7>2021-2022 Patriot Band</h7>
                <h6>All Albums</h6>
              <a href="https://www.flickr.com/photos/creativedna/albums/72157719763459323"
                target="_blank" rel="noopener noreferrer">
              <img
                src={band}
                alt="..."
                className={classes.imgRounded + " " + classes.imgFluid}
              />
              </a>
            </GridItem>
            <GridItem xs={12} sm={2}>
              <h7>2021 Summer 7v7 Men's Soccer</h7>
                <h6>All Albums</h6>
              <a href="https://www.amazon.com/clouddrive/share/fa5Z7Ci5UBdWW3DqLEdxTOxzOjFbhZkipueCemPn26x"
                target="_blank" rel="noopener noreferrer">
              <img
                src={soccer}
                alt="..."
                className={classes.imgRounded + " " + classes.imgFluid}
              />
              </a>
            </GridItem>
            <GridItem xs={12} sm={2}>
              <h7>2020-2021 Men's Soccer</h7>
                <h6>All Albums</h6>
              <a href="https://www.amazon.com/clouddrive/share/TJ1TD9AhScfjLM9fm2fXMWEFFaOndNP2ukAP51ZO89r"
                target="_blank" rel="noopener noreferrer">
              <img
                src={soccer}
                alt="..."
                className={classes.imgRounded + " " + classes.imgFluid}
              />
              </a>
            </GridItem>
            <GridItem xs={12} sm={2}>
              <h7>2020-2021 Women's Soccer</h7>
                <h6>All Albums</h6>
              <a href="https://www.amazon.com/clouddrive/share/njNO1WSgh4BcqqCEWRHG2wO8xqAxeikYvpqyKHKwNrJ"
                target="_blank" rel="noopener noreferrer">
              <img
                src={soccer}
                alt="..."
                className={classes.imgRounded + " " + classes.imgFluid}
              />
              </a>
            </GridItem>
            <GridItem xs={12} sm={2}>
              <h7>2020-2021 Patriot Softball</h7>
                <h6>All Albums</h6>
              <a href="https://www.amazon.com/clouddrive/share/FmsZ8ZuRQlG2n4UfmIRFPVlale6xD9RXvuReYpjEKON"
                target="_blank" rel="noopener noreferrer">
              <img
                src={softball}
                alt="..."
                className={classes.imgRounded + " " + classes.imgFluid}
              />
              </a>
            </GridItem>
            <GridItem xs={12} sm={2}>
              <h7>2020-2021 Patriot Track & Field</h7>
                <h6>All Albums</h6>
              <a href="https://www.amazon.com/clouddrive/share/eaPzkTtsDiXztMxiMwDyJr1s3g2cmyjuKXCjf1Iolwp"
                target="_blank" rel="noopener noreferrer">
              <img
                src={track}
                alt="..."
                className={classes.imgRounded + " " + classes.imgFluid}
              />
              </a>
            </GridItem>
            <GridItem xs={12} sm={2}>
              <h7>2020-2021 Patriot Powder Puff Football</h7>
                <h6>All Albums</h6>
              <a href="https://www.amazon.com/clouddrive/share/jfW6ZAbklvvFrUCGUALHUA0ubNxuYKoo2mxeA60klcp"
                target="_blank" rel="noopener noreferrer">
              <img
                src={evpp}
                alt="..."
                className={classes.imgRounded + " " + classes.imgFluid}
              />
              </a>
            </GridItem>
          </GridContainer>
          <GridContainer />
        </div>
        <div className={classes.space50} />
      </div>
    </div>
  );
}
